<template>
  <div class="van-cell calendars">
    <van-field
      readonly
      clickable
      :name="name"
      :value="date"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      @click="handField()"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="pickerModel"
        :type="type"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    type: {
      type: String,
      default: "time",
    },
    name: {
      type: String,
      default: "",
    },
    value: null,
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: null,
    },
    minDate: {
      type: Date,
      default: () => new Date(),
    },
    maxDate: {
      type: Date,
      default: () => new Date(new Date().setMonth(new Date().getMonth() + 6)),
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    valueFormat: {
      type: String,
      default: "YYYYY-MM-DD",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      showPicker: false,
      pickerModel: null,
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.value) {
          if (this.type === "time") {
            this.date = this.value;
          } else {
            this.date = moment(this.value).format(this.format);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handField() {
      if (!this.disabled) {
        if (this.date) {
          if (this.type === "time") {
            this.pickerModel = this.date;
          } else {
            this.pickerModel = new Date(this.date);
          }
        }
        this.showPicker = true;
      }
    },
    // 处理控件显示的时间格式
    formatter(type, value) {
      // 格式化选择器日期
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      } else if (type === "day") {
        return `${value}日`;
      } else if (type === "hour") {
        return `${value}时`;
      } else if (type === "minute") {
        return `${value}分`;
      }
      return value;
    },
    onConfirm(time) {
      if (this.type === "time") {
        this.date = time;
        this.$emit("input", time);
      } else {
        this.date = moment(time).format(this.format);
        this.$emit("input", moment(time).format(this.valueFormat));
      }
      this.showPicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
</style>
