export default function attr(attrs, key, value) {
  switch (key) {
    case 'autosize':
      attrs['rows'] = `${value.maxRows}`;
      break;
    default:
      attrs[key] = value;
      break;
  }
}
