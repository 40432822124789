<template>
  <div class="van-cell calendars">
    <van-field
      readonly
      clickable
      :name="name"
      :value="date"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      @click="handField()"
    />
    <van-calendar
      v-model="show"
      :type="type"
      :min-date="minDate"
      :max-date="maxDate"
      :color="color"
      :default-date="defaultDate"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    type: {
      type: String,
      default: "single",
    },
    name: {
      type: String,
      default: "",
    },
    value: null,
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请选择日期范围",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: null,
    },
    minDate: {
      type: Date,
      default: () => new Date(new Date().setMonth(new Date().getMonth() - 6)),
    },
    maxDate: {
      type: Date,
      default: () => new Date(new Date().setMonth(new Date().getMonth() + 6)),
    },
    color: {
      type: String,
      default: "#1989fa",
    },
    rangeSeparator: {
      type: String,
      default: "-",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    valueFormat: {
      type: String,
      default: "YYYYY-MM-DD",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      date: null,
      defaultDate: [],
    };
  },
  watch: {
    value: {
      handler: function () {
        this.handValue();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handField() {
      this.defaultDate = [];
      if (!this.disabled) {
        if (this.type === "range") {
          if (this.value) {
            this.value.forEach((item) => {
              this.defaultDate.push(new Date(item));
            });
          } else {
            this.defaultDate.push(new Date());
          }
        } else {
          if (this.value) {
            this.date = this.value;
            this.defaultDate = new Date(this.value);
          } else {
            this.defaultDate = new Date();
          }
        }
        this.show = true;
      }
    },
    handValue() {
      if (this.value) {
        if (this.type === "range") {
          if (this.value !== undefined && this.value.length > 0) {
            this.date = this.value.join(this.rangeSeparator);
          }
        } else {
          this.date = this.value;
        }
      }
    },
    onConfirm(date) {
      if (this.type === "single") {
        this.date = moment(date).format(this.format);
        this.$emit("input", moment(date).format(this.valueFormat));
      } else if (this.type === "range") {
        const [start, end] = date;
        let Datas = [];
        this.date =
          `${moment(start).format(this.format)} ` +
          this.rangeSeparator +
          ` ${moment(end).format(this.format)}`;
        Datas.push(moment(start).format(this.valueFormat));
        Datas.push(moment(end).format(this.valueFormat));
        this.$emit("input", Datas);
      }
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
</style>