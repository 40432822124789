export default function attr(attrs, key, value) {
    switch (key) {
        case 'type':
            attrs['type'] = value === 'daterange' ? 'range' : 'single';
            break;
        case 'value-format':
            attrs['value-format'] = value.replace("yyyy", "YYYY").replace("dd", "DD");
            break;
        case "format":
            attrs["format"] = value.replace("yyyy", "YYYY").replace("dd", "DD").replace(":ss", "");
            break;
        default:
            attrs[key] = value;
            break;
    }
}