<template>
  <div class="van-cell calendars">
    <!-- input-align="right" -->
    <van-field
      readonly
      clickable
      v-model="values"
      :label="label"
      :labelWidth="labelWidth"
      :placeholder="placeholder"
      :rules="rules"
      :is-link="!readonly"
      @click="handField"
    />
    <van-popup
      v-model="showPicker"
      closeable
      round
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popup-content">
        <div class="popup-content-label">
          <span>{{ label }}</span>
        </div>
        <div class="popup-content-times">
          <div class="popup-content-times-left pctl-default">
            <div class="pctl-data pctl-default">
              <div
                class="pctl-content"
                v-for="(data, index) in optionData"
                :key="index"
                :class="data.value == leftValue ? 'pctl-pitch ' : ''"
                @click="handerLeftDate(data, index)"
              >
                <div class="pctl-icon" v-if="data.value === leftValue"></div>
                <div class="pctl-text">{{ data.label }}</div>
              </div>
            </div>
          </div>
          <div class="popup-content-times-right">
            <div class="pctr-data">
              <div
                class="pctr-content"
                v-for="(data, index) in childrenData"
                :key="index"
                :class="data.value == rightValue ? 'pctl-pitch ' : ''"
                @click="handerRightDate(data, index)"
              >
                <div class="pctr-text">{{ data.label }}</div>
                <div class="pctr-icon" v-if="data.value === rightValue">
                  <van-icon name="success" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    labelWidth: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    options: {
      type: [Array, Object],
      default: null
    },
    timeState: {
      type: Boolean,
      default: true
    },
    separator: {
      type: String,
      default: '/'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      values: '',
      showPicker: false,
      optionData: [],
      childrenData: [],
      leftLabel: '',
      rightLabel: '',
      leftValue: '',
      rightValue: ''
    };
  },
  watch: {
    timeState: {
      handler: function() {
        if (this.timeState) {
          this.optionData = this.handerItem();
        } else {
          this.optionData = this.options;
        }
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function() {
        if (this.value) {
          this.valueDatas();
        } else {
          this.valueDefault();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handField() {
      if (!this.readonly) {
        this.showPicker = true;
      }
    },
    handerLeftDate(data, i) {
      this.leftValue = data.value;
      this.childrenData = data.children;
      this.rightValue = '';
    },
    handerRightDate(data, i) {
      this.rightValue = data.value;
      this.values = this.leftValue + this.separator + this.rightValue;
      this.showPicker = false;
      let dataVal = [this.leftValue, this.rightValue];
      this.$emit('input', JSON.stringify(dataVal));
    },
    valueDatas() {
      let data = JSON.parse(this.value);
      this.leftValue = data[0];
      this.rightValue = data[1];
      this.optionData.forEach((item) => {
        if (item.value == this.leftValue) {
          this.childrenData = item.children;
          this.leftLabel = item.label;
        }
        this.valueText(item.children);
      });
      if (this.timeState) {
        this.values = this.leftValue + this.separator + this.rightValue;
      }
      if (this.leftLabel && this.rightLabel) {
        this.values = this.leftLabel + this.separator + this.rightLabel;
      } else {
        this.valueDefault();
      }
    },
    valueDefault() {
      let data = this.optionData[0];
      this.leftValue = data.value;
      this.rightValue = '';
      this.childrenData = data.children;
    },
    valueText(data) {
      data.forEach((item) => {
        if (item.value == this.rightValue) {
          this.rightLabel = item.label;
        }
      });
    },
    handerItem() {
      let item = [];
      let newDate = moment(new Date()).format('YYYY-MM-DD');
      let childrenData = this.handerChildren();
      for (let i = 0; i < 6; i++) {
        let nextDate = moment(newDate)
          .add(i + 1, 'days')
          .format('YYYY-MM-DD');
        let nextLabel = i === 0 ? '明天' : i === 1 ? '后天' : moment(nextDate).format('MM月DD日');
        let data = {
          id: i,
          value: nextDate,
          label: nextLabel,
          children: childrenData
        };
        item.push(data);
      }
      if (!this.value) {
        this.leftValue = item[0].value;
      } else {
        this.leftValue = this.value[0];
      }
      return item;
    },
    handerChildren() {
      let item = [];
      for (let i = 9; i < 16; i++) {
        let val = i < 10 ? '0' + i + ':00' : i + ':00';
        let nextVal = i + 1 < 10 ? '0' + (i + 1) + ':00' : i + 1 + ':00';
        let data = {
          id: i,
          value: val + '-' + nextVal,
          label: val + '-' + nextVal
        };
        item.push(data);
      }
      if (!this.value) {
        this.rightValue = item[0].value;
      } else {
        this.rightValue = this.value[1];
      }
      return item;
    }
  }
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
.popup-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .popup-content-label {
    height: 54px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
    line-height: 54px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #333333;
  }
  .popup-content-times {
    display: flex;
    flex: 1;
    overflow: scroll;
    .popup-content-times-left {
      display: flex;
      flex: 0.7;
      flex-direction: column;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      overflow: scroll;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      .pctl-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        .pctl-content {
          position: relative;
          height: 48px;
          line-height: 48px;
          .pctl-icon {
            position: absolute;
            top: 16px;
            left: 0;
            width: 4px;
            height: 16px;
            background: #4285f4;
          }
          .pctl-text {
            margin-left: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .popup-content-times-right {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      overflow: scroll;
      overflow: -moz-scrollbars-none;
      .pctr-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        .pctr-content {
          display: flex;
          height: 48px;
          line-height: 48px;
          .pctr-text {
            margin-left: 14px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pctr-icon {
            width: 32px;
            color: #4285f4;
          }
        }
      }
    }
    .popup-content-times-pack::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}
.pctl-default {
  background: #f6f6f6;
}
.pctl-pitch {
  background: #ffffff;
  color: #4285f4;
}
</style>
