<template>
  <van-field :name="name" :label="label" :required="requiredRate" :rules="rules">
    <template #input>
      <van-stepper
        v-model="stepper"
        :disabled="disabledRate"
        :step="step"
        :min="min"
        :max="max"
        :decimal-length="precision"
        :theme="themeType"
        @change="onChange"
      />
    </template>
  </van-field>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: null,
    },
    value: {
      type: Number,
      default: 50,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    precision: {
      type: Number,
      default: 0,
    },
    controlsPosition: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stepper: this.value,
      themeType: this.controlsPosition === "right" ? "round" : "",
      disabledRate: this.disabled,
      requiredRate: this.required
    };
  },
  watch: {
    readonly: {
      handler:function(){
        if(this.readonly){
          this.disabledRate = true;
          this.requiredRate = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    onChange(value) {
      this.$emit("input", parseInt(value));
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #1989fa;
  border: none;
  color: #ffffff;
}
/deep/.van-stepper--round .van-stepper__plus {
  background-color: #1989fa;
}
</style>
