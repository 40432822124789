export default function attr(attrs, key, value) {
  switch (key) {
    case 'size':
      attrs['size'] = value;
      attrs['direction'] = 'horizontal';
      break;
    default:
      attrs[key] = value;
      break;
  }
}
