
let optionCascader = [];

function cascaderText(data, value) {
  if (data) {
    data.forEach((Item) => {
      if (value.includes(Item.value)) {
        optionCascader.push(Item.label);
      }
      if (Item.children && Item.children.length > 0) {
        cascaderText(Item.children, value);
      }
    });
  }
}

export default function dateFormat(conf, slots) {
  let tet = "";
  let value = conf.__config__.defaultValue;
  let type = conf['type'];
  let separator = conf['separator'];
  let options = slots.options || [];
  let dataList = conf['options'];
  
  if (type === "cascader") {
    optionCascader = [];
    cascaderText(dataList, value);
    tet = optionCascader.join(separator);
  }else{
    let item = options.find((item) => item.value === value);
    tet = item ? item.label : '';
  }
  return tet;
}
