<script>
import { deepClone } from '@/utils/index';
import render from '@/components/vant-render/render.js';
import VcRow from '@/components/vc-row';

const ruleTrigger = {
  'van-field': 'onBlur'
};

const tagMappers = {
  'el-input': 'van-field',
  'el-rate': 'van-rate',
  'el-radio-group': 'van-radio-group',
  'el-checkbox-group': 'van-checkbox-group',
  'el-upload': 'vc-uploader',
  'el-date-picker': 'vc-calendar',
  'el-time-picker': 'vc-datetime',
  'el-slider': 'vc-slider',
  'el-input-number': 'vc-stepper',
  'el-select': 'vc-picker',
  'el-cascader': 'vc-cascader',
  'el-switch': 'vc-switch',
  'two-cascade': 'vc-two-cascade',
  'map-position': 'vc-map-position',
  'input-button': 'vc-input-button'
};

const dateTimeType = ['datetime', 'month'];

function initFormTag(componentList) {
  componentList.forEach((cur) => {
    const config = cur.__config__;

    if (config.componentName) {
      config.tag = config.componentName;
    }

    if (config.tag === 'el-date-picker' && dateTimeType.includes(cur.type)) {
      config.tag = 'vc-datetime';
    }
    if (config.tag === 'el-upload') {
      cur.fileSize = config.fileSize;
      cur.sizeUnit = config.sizeUnit;
    }
    if (config.tag === 'el-select') {
      cur.type = 'select';
    }
    config.tag = tagMappers[config.tag] ? tagMappers[config.tag] : config.tag;
    if (config.children) initFormTag(config.children);
  });
}

const layoutMappers = {
  top: 'vertical',
  left: 'horizontal',
  right: 'horizontal'
};

const fieldTags = ['van-radio-group','van-checkbox-group'];

const layouts = {
  colFormItem(h, scheme, params) {
    const config = scheme.__config__;
    const listeners = buildListeners.call(this, scheme);
    const rules = buildRules.call(this, scheme);
    const fieldProps = {
      label: config.showLabel ? config.label : '',
      labelWidth: config.showLabel ? (config.labelWidth ? `${config.labelWidth}px` : null) : null,
      // required: config.required,
      rules,
      name: scheme.__vModel__
    };
    if (fieldTags.includes(config.tag)) {
      return (
        <van-field props={fieldProps}>
          <template slot="input">
            <render conf={scheme} params={params} {...{ on: listeners }} />
          </template>
        </van-field>
      );
    }
    return <render conf={{ ...scheme, ...fieldProps }} params={params} {...{ on: listeners }} />;
  },
  rowFormItem(h, scheme, params) {
    let child = renderChildren.apply(this, arguments);
    return <vc-row>{child}</vc-row>;
  }
  // cardFormItem(h, scheme, params) {
  //   let child = renderChildren.apply(this, arguments);
  //   return <vc-card>{child}</vc-card>;
  // }
};

function renderDescriptions(h) {
  const { formConfCopy } = this;
  return <div>{renderDescriptionsItem.call(this, h, formConfCopy.fields)}</div>;
}

function renderDescriptionsItem(h, elementList) {
  const { formConfCopy, params } = this;
  return elementList.map((scheme) => {
    const config = scheme.__config__;
    const listeners = buildListeners.call(this, scheme);
    const fieldProps = {
      label: config.showLabel ? config.label : '',
      required: config.required,
      name: scheme.__vModel__
    };
    if (config.layout === 'colFormItem') {
      return (
        <render
          conf={{ ...scheme, ...fieldProps }}
          params={params}
          description={formConfCopy.readonly}
          {...{ on: listeners }}
        />
      );
    } else if (config.layout === 'rowFormItem') {
      let child = renderDescriptionsItem.call(this, h, config.children)
      return <vc-row>{child}</vc-row>;
    }
  });
}

function renderFrom(h) {
  const { formConfCopy } = this;
  let classNames = {
    'van-form-parser': true,
    [`van-form--label-${formConfCopy.labelPosition}`]: formConfCopy.labelPosition
  };
  return (
    <van-form autocomplete="off"
      class={classNames}
      disabled={formConfCopy.disabled}
      ref={formConfCopy.formRef}
      onSubmit={this.submitForm}
      onFailed={this.failedForm}
    >
      {renderFormItem.call(this, h, formConfCopy.fields)}
      {formConfCopy.formBtns && formBtns.call(this, h, formConfCopy)}
    </van-form>
  );
}

function formBtns(h) {
  const { formConfCopy } = this;

  return (
    <div class="vant-form--submit-btns">
      <van-button type="primary" size="large" color="#4286f5" native-type="submit">
        提交
      </van-button>
    </div>
  );
}

function renderFormItem(h, elementList) {
  const { formConfCopy, params } = this;

  return elementList.map((scheme) => {
    const config = scheme.__config__;
    let formItem = config.layout;
    // if (config.tag === 'vc-card') {
    //   formItem = 'cardFormItem';
    // }
    const layout = layouts[formItem];
    if (layout) {
      return layout.call(this, h, scheme, params);
    }
    throw new Error(`没有与${formItem}匹配的layout`);
  });
}

function renderChildren(h, scheme) {
  const config = scheme.__config__;
  if (!Array.isArray(config.children)) return null;
  return renderFormItem.call(this, h, config.children);
}

function setValue(event, scheme) {
  const config = scheme.__config__;
  this.$set(config, 'defaultValue', event);
  this.$set(this[this.formConf.formModel], scheme.__vModel__, event);
}

function buildListeners(scheme) {
  const config = scheme.__config__;
  const methods = this.formConf.__methods__ || {};
  const listeners = {};

  // 给__methods__中的方法绑定this和event
  Object.keys(methods).forEach((key) => {
    listeners[key] = (event) => methods[key].call(this, event);
  });
  // 响应 render.js 中的 vModel $emit('input', val)
  listeners.input = (event) => setValue.call(this, event, scheme);

  listeners.change = (event) => {};

  return listeners;
}

function buildRules(scheme) {
  const config = scheme.__config__;
  const regList = [];

  if (config.required) {
    const required = {
      required: config.required
      // message: scheme.placeholder
    };
    if (Array.isArray(config.defaultValue)) {
      required.type = 'array';
      // required.message = `请至少选择一个${config.label}`;
    }
    // required.message === undefined && (required.message = `${config.label}不能为空`);
    regList.push(required);
  }

  if (Array.isArray(config.regList)) {
    config.regList.forEach((item) => {
      let pattern = null;
      if (item.pattern) {
        pattern = eval(item.pattern);
      }
      regList.push({
        ...item,
        pattern: pattern,
        trigger: ruleTrigger && ruleTrigger[config.tag]
      });
    });

    return regList;
  }
}

export default {
  components: {
    render,
    VcRow
  },
  props: {
    formConf: {
      type: Object,
      required: true
    },
    params: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    initFormTag(this.formConf.fields);

    const data = {
      formConfCopy: deepClone(this.formConf),
      [this.formConf.formModel]: {}
    };

    this.initFormData(data.formConfCopy.fields, data[this.formConf.formModel]);

    return data;
  },
  methods: {
    initFormData(componentList, formData) {
      componentList.forEach((cur) => {
        const config = cur.__config__;

        if (cur.__vModel__) formData[cur.__vModel__] = config.defaultValue;
        if (config.children) this.initFormData(config.children, formData);
      });
    },
    // 填充表单
    fillForm(data) {
      let VM = this;
      function fill(fields) {
        fields.forEach((item) => {
          const val = data[item.__vModel__];
          if (typeof val !== 'undefined') {
            setValue.call(VM, val, item);
          }
          if (item.type === 'default' && item.__config__.children.length) {
            fill(item.__config__.children);
          }
          if (item.children && item.children.length) {
            fill(item.children);
          }
        });
      }
      fill(this.formConfCopy.fields);
    },
    resetForm() {
      this.formConfCopy = deepClone(this.formConf);
      this.$refs[this.formConf.formRef].resetFields();
      this.$emit('reset');
    },
    submitForm() {
      // 触发sumit事件
      this.$emit('submit', this[this.formConf.formModel]);
    },
    failedForm() {
      this.$emit('failed', this[this.formConf.formModel]);
    }
  },
  render(h) {
    if (this.formConf.readonly) {
      return renderDescriptions.call(this, h);
    }
    return renderFrom.call(this, h);
  }
};
</script>

<style lang="less">
.vant-form--submit-btns {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>
